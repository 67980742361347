<template>
  <div>
    <el-backtop></el-backtop>
    <img src="../assets/image/新闻资讯/资讯中部广告.png" alt="" />
    <div class="company-content">
      <h2 class="title">企业资讯</h2>
      <div class="company-card">
        <div class="card-content" @click="companyOne">
          <img src="../assets/image/新闻资讯/资讯1.png" alt="" />
          <h4>宜昌交通局公共交通智慧监控平台顺利落地</h4>
          <p>宜昌交通局公共交通智慧监控平台一期，以公交数据为基准......</p>
        </div>
        <div class="card-content" @click="companyTwo">
          <img src="../assets/image/新闻资讯/资讯2.png" alt="" />
          <h4>宜昌公交集团智慧安全管理平台正式上线运营</h4>
          <p>智慧安全管理平台集成了公交行业日常安全管理全范畴业务.....</p>
        </div>
        <div class="card-content" @click="companyThree">
          <img src="../assets/image/新闻资讯/资讯3.png" alt="" />
          <h4>武汉轩迪科技有限公司2021年正式启航</h4>
          <p>2021年3月武汉轩迪科技有限公司正式注册成立，原市场与......</p>
        </div>
      </div>
    </div>
    <div class="industry-content">
      <h2 class="title">行业新闻</h2>
      <div class="company-card">
        <div class="card-content" id="companyFour" @click="industryOne">
          <img src="../assets/image/新闻资讯/新闻1.png" alt="" />
          <h4>智能调度系统统计考核的主要功能</h4>
          <p>智能调度系统统计考核通过对海量的车辆定位数据进行挖掘......</p>
        </div>
        <div class="card-content" id="companyFive" @click="industryTwo">
          <img src="../assets/image/新闻资讯/新闻2.png" alt="" />
          <h4>数据风暴</h4>
          <p>随着新一代信息技术飞速发展、信息化人才和专家的不断辈......</p>
        </div>
        <div class="card-content" id="companySix" @click="industryThere">
          <img src="../assets/image/新闻资讯/新闻3.png" alt="" />
          <h4>公交驾驶员生命体征的智能监测技术简介</h4>
          <p>4月25日，中国土木工程学会城市公共交通分会理事长工作......</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // companyContent: [
      //   {
      //     img: require('../assets/image/关于我们/美团.png'),
      //     title: '宜昌交通局公共交通只能监控平台顺利落地',
      //     content:
      //       '宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地'
      //   },
      //   {
      //     img: require('../assets/image/关于我们/美团.png'),
      //     title: '宜昌交通局公共交通只能监控平台顺利落地',
      //     content:
      //       '宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地'
      //   },
      //   {
      //     img: require('../assets/image/关于我们/美团.png'),
      //     title: '宜昌交通局公共交通只能监控平台顺利落地',
      //     content:
      //       '宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地宜昌交通局公共交通只能监控平台顺利落地'
      //   }
      // ],
    }
  },
  methods: {
    companyOne() {
      this.$router.push('/companyNewsOne')
    },
    companyTwo() {
      this.$router.push('/companyNewsTwo')
    },
    companyThree() {
      this.$router.push('/companyNewsThree')
    },
    industryOne() {
      this.$router.push('/industryNewsOne')
    },
    industryTwo() {
      this.$router.push('/industryNewsTwo')
    },
    industryThere() {
      this.$router.push('/industryNewsThree')
    }
  }
}
</script>
<style lang="less" scoped>
h4 {
  height: 86px;
}
img {
  width: 100%;
}
.title {
  font-size: 48px;
  color: #333;
}
.company-card {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;
  margin-top: 10px;
}
.company-content {
  height: 800px;
  padding: 80px 160px;
}
h4 {
  font-size: 32px;
  color: #333;
}
p {
  margin-top: 15px;
  width: 400px;
  height: 75px;
  font-size: 28px;
  color: #666;
  line-height: 48px;
}
.industry-content {
  height: 800px;
  padding: 60px 160px 180px;
  background: #f6f6f6;
}
.card-content {
  height: 300px;
  cursor: pointer;
  article {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.title::after {
  content: '';
  display: block;
  width: 120px;
  height: 2px;
  margin-top: 8px;
  background-color: #3468d2;
}
</style>
